<script>
    import PageHeader from "@/components/structure/page-header";
    import appConfig from "@/app.config";
    import Paginate from "@/components/pagination";
    import helper from '@/assets/helpers/helper';
    import JoinComp from "@/components/popups/joinComp";
    import UpcomingCompetitions from "@/components/upcomingCompetitions";
    import LiveCompetitions from "@/components/liveCompetitions";
    export default {
        page: {
            title: "Competitions",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {
            LiveCompetitions,
            UpcomingCompetitions,JoinComp, Paginate, PageHeader},
        name: 'Competitions',
        data() {
            return {
                title: "Competitions",

                current_page: 1,
                last_page_url: 6,
                currentSort:'name',
              currentSortDir:'ascending',
              none:'none',
                selectedType:'',
                selectedId:'',
                isLoadedComp:false,

                competitions: [],


            };
        },

         created() {

             this.loadCompetitions(1)
        },
        methods:{

            async loadCompetitions(page){
                let competitions = await helper.loadData('competitions', page);
                this.competitions = competitions.data;
                this.isLoadedComp = true;
                this.current_page = competitions.current_page
                this.last_page_url = competitions.last_page;
            },

            sort:function(s) {
                //if s == current sort, reverse
                if(s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir==='ascending'?'descending':'ascending';
                }
                this.currentSort = s;
            },
            changePage(page) {
                this.loadCompetitions(page)
            },
        },
        computed:{
            competitions_sorted:function() {
                let competitions = this.competitions;
                return competitions.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'descending') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }
        },
    };
</script>
<template>
    <div>
        <PageHeader :title="$t('profil_comp.title_tab')"/>
        <div class="row">
            <div class="col-xl-8">

                <div class="row">
                    <div class="col">

                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <h4 class="card-title  mb-4">{{$t('profil_comp.title_tab')}}</h4>
                                        <p  v-if="competitions.length == 0 && isLoadedComp" class="text-center">
                                            {{$t('validation.msg')}} <br/>
                                        </p>
                                    </div>
                                  <div class="col-xl-6 mb-2 text-right">
                                    <button type="button" class="btn mr-3  btn-sm btn-primary" @click="$router.push({name:'MyCompetitions'})">{{$t('navbar.dropdown.henry.list.my_competitions')}}</button>
                                    <button type="button" class="btn btn-sm btn-primary"  @click="$bvModal.show('modal-join-comp')" >{{$t('dashboard.comp.join_private')}}</button>
                                        <join-comp/>
                                    </div>
                                </div>
                                    <div v-if="!isLoadedComp">
                                        <b-skeleton-table :rows="5" :columns="6"></b-skeleton-table>
                                        <b-skeleton></b-skeleton>
                                    </div>
                                <div v-if="competitions.length && isLoadedComp">
                                    <table class=" tab table b-table text-center" role="table" >
                                    <thead>
                                    <tr>
                                        <th scope="col" @click="sort('name')" role="columnheader"  :aria-sort="(currentSort == 'name')?currentSortDir:none">{{$t('profil_comp.name')}}</th>
                                        <th scope="col" @click="sort('begin_at')" role="columnheader" :aria-sort="(currentSort == 'begin_at')?currentSortDir:none">{{$t('profil_comp.began_at')}}</th>
                                        <th scope="col" @click="sort('finish_at')" role="columnheader" :aria-sort="(currentSort == 'finish_at')?currentSortDir:none">{{$t('profil_comp.finished_at')}}</th>

                                        <th scope="col" @click="sort('challenges_count')" role="columnheader" :aria-sort="(currentSort == 'challenges_count')?currentSortDir:none">{{$t('profil_comp.challenges')}}</th>
                                        <th scope="col" @click="sort('teams_count')" role="columnheader" :aria-sort="(currentSort == 'teams_count')?currentSortDir:none">{{$t('profil_comp.teams')}}</th>
                                        <th scope="col" >{{$t('profil_comp.actions')}}</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="data of competitions_sorted" :key="data.id">
                                        <td :data-label="$t('profil_comp.name')"><router-link class="h6 link" :to="{name:'ProfilCompetition',params:{id:data.id}}">{{data.name}}</router-link></td>
                                        <td :data-label="$t('profil_comp.began_at')"  :title="'UTC | '+data.begin_at"> {{data.begin_at}}</td>
                                        <td :data-label="$t('profil_comp.finished_at')"  :title="'UTC | '+data.finish_at"> {{data.finish_at}}</td>
                                        <td :data-label="$t('profil_comp.challenges')"> {{data.challenges_count}}</td>
                                        <td :data-label="$t('profil_comp.teams')">{{data.teams_count}}</td>
                                        <td :data-label="$t('profil_comp.actions')">
                                            <ul class="list-inline font-size-16 text-center contact-links mb-0">
                                                <li class="list-inline-item  link ">
                                                    <router-link type="button"
                                                            class="btn btn-primary btn-sm mb-1 text-white btn-rounded" :to="{name:'ScoreBoardCompetition',params:{id:data.id}}" >{{$t('profil_comp.scoreboard')}}
                                                    </router-link>
                                                </li>
                                                <li class="list-inline-item link">
                                                    <router-link type="button" class="btn btn-primary text-white mb-1 btn-sm btn-rounded" :to="{name:'statisticsCompetition',params:{id:data.id}}">
                                                        {{$t('profil_comp.statistics')}}
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>


                                    </tbody>
                                </table>
                                    <div class="row  my-4">
                                        <div class="col">
                                            <div class="text-center">
                                                <paginate  :current_page=current_page :last_page_url=last_page_url
                                                          v-on:change-page="changePage"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>

            </div>
            <div class="col-xl-4">
                <live-competitions/>
               <upcoming-competitions/>

            </div>

        </div>



    </div>
</template>
<style scoped>
    .check-nav-icon {
        font-size: 26px !important;
    }

    .challenge {
        cursor: pointer;
        transition: all .2s ease-in-out;
    }

    .challenge:hover {
        transform: scale(1.1);
        background-color: #34c38f;
    }

    .active {
        background-color: #556ee6;
    }




</style>