<template>
    <b-modal id="modal-join-comp"  ref="modal"  :title="$t('dashboard.comp.join_desc')" title-class="font-18" hide-footer @shown="create">

                    <div role="tablist">

                        <b-card no-body class="mb-1 shadow-none">
                                <b-card-body>
                                    <form id="form"  method="POST" @submit.prevent="handleSubmit">
                                        <div class="row" >
                                            <div class="col-xl-10">
                                            <input type="text" class="form-control chat-input pr-3 mr-2" style="border-radius: 30px" v-model="code"
                                                   :placeholder="$t('profil_team.join.code')"
                                                   :class="{ 'is-invalid': typesubmit && $v.code.$error }"/>
                                        <div v-if="typesubmit && $v.code.$error" class="invalid-feedback">
                                          <span v-if="!$v.code.required"> {{$t('validation.required')}}</span>
                                            <span v-if="!$v.code.valid"> xxxx-xxxx-xxxx-xxxx</span>
                                        </div>
                                            </div>
                                             <div class="col-xl-2">
                                             <input type="submit" class="btn btn-rounded btn-secondary" :value="$t('profil_team.submit')"/>
                                        </div>
                                        </div>

                                    </form>
                                </b-card-body>
                        </b-card>

                    </div>
    </b-modal>
</template>

<script>


    import {required} from "vuelidate/lib/validators";
    import Swal from "sweetalert2";

    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: false,
        timer: 2000,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    export default {
        name: "joinComp",
        data(){
            return{
                code:'',
                typesubmit: false

            }
        },
        validations: {
            code:{
                required,
                valid: function(value) {
                    return /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/.test(value)
                }
            }
        },

        methods: {
            create(){
                this.code=''
                this.typesubmit = false;
            },
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.join();

            },
            join(){
                this.$http
                    .post('competitions/private/join',
                        {
                            code  : this.code,
                        })
                    .then(response => {
                        console.log(response)
                        Toast.fire({icon: 'success', title: response.data.msg})
                        this.$refs['modal'].hide()
                        this.$router.push({ name: 'ProfilCompetition', params: { id: response.data.competition_id }})
                    })
                    .catch(error => {
                        console.log(error)
                        Toast.fire({icon: 'error', title: 'Oops...', text: error.response.data.error})
                    })
            },

        },
    }
</script>

<style scoped>

</style>